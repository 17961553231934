#home, #about, #art, #projects, #music, #writing, #everydaysounds, 
#vrcooking, #bloombergconnects, #adsnytgames{
    position: fixed;
    display:flex;
    width: 100%;
    height: 100%;
}

#leftpanel{
    overflow-y: scroll;
    width:60%;
}

#leftpanel_wide{
    overflow-y: scroll;
    width:70%;
}

#rightpanel{
    position: relative;
    width:40%;
}

#rightpanel_slim{
    position: relative;
    width:30%;
}

@import url('https://fonts.googleapis.com/css2?family=Libre+Barcode+128+Text&family=MuseoModerno:wght@100;200;300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Limelight&family=MuseoModerno:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Moderustic:wght@300..800&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Quicksand:wght@300..700&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Franklin:ital,wght@0,100..900;1,100..900&family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap');
#name{
    font-weight: 200;
    color: black;
    font-family: 'MuseoModerno', cursive;
}

div{
    font-family: 'MuseoModerno', cursive;
}

.helvetica{
    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; 
    font-weight: 300;
}

#summary{
    position: fixed;
    bottom: 0%;
    left: 2%;
    font-size:1.8vw;
    font-weight: 200;
    width: 50%;
    font-family: 'MuseoModerno', cursive;
    font-style: normal;
}

#content{
    width: 90%;
    padding-left: 4%;
    padding-top: 7%;
    overflow: auto;
    font-family: 'MuseoModerno', cursive;
    font-weight: 300;
    overflow-x: hidden;
}

  @media screen and (max-width: 600px) {
    #content {
      font-size: 3vw;
      margin-top: 10%;
      margin-left: 1%;
    }
    #summary {
        font-size: 3.5vw;
        bottom: 1%;
        left: 4%;
    }
    #name{
        font-size: 9vw;
        margin-top: 10%;
        margin-left: 3%;
    }
    #leftpanel{
        width: 60%;
        height: 100%;
        max-height: 100%;
        overflow-y: scroll;
        min-height: 100%;
    }
    #leftpanel_wide{
        width: 60%;
        height: 100%;
        max-height: 100%;
        overflow-y: scroll;
        min-height: 100%;
    }
    
    #rightpanel{
        width:40%;
        height: 100%;
        max-height: 100%;
        overflow-y: scroll;
        min-height: 100%;
    }

    #rightpanel_slim{
        width:40%;
        height: 100%;
        max-height: 100%;
        overflow-y: scroll;
        min-height: 100%;
    }
  }