@import url('https://fonts.googleapis.com/css2?family=Libre+Barcode+128+Text&family=MuseoModerno:wght@100;300;400&display=swap');

.page_text {
    text-align: right;
    position: absolute;
    bottom: 4%;
    right: 5%;
    color: white;
    font-size: 1.5vw;
    pointer-events: none;
    font-weight: 200;
    font-family: 'MuseoModerno', cursive;
  }

.space {
    position: relative;
    text-align: center;
    height: 20%;
    width:33.33333%;
}
  
.projects,.writing,.art,.about{
    cursor: pointer;
}

/* If the screen size is 600px wide or less, set the font-size of <div> to 30px */
@media screen and (max-width: 600px) {
    .page_text {
        font-size: 2.9vw;
        font-weight: 400;
    }
  }
