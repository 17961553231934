@keyframes pulse {
  0% {
    border-color: transparent;
  }
  100% {
    border-color: #a5b4fc;
  }
}

.pulse-border::before {
  content: '';
  position: absolute;
  top: 0px; 
  left: -3px; 
  right: 0px;
  bottom: -3px;
  border-bottom: 4px solid transparent; 
  border-left: 4px solid transparent;
  animation: pulse 1s infinite alternate; 
}

@media screen and (min-width: 601px) {
    .title {
      font-size: 1.7vw;
    }
    .description {
        font-size: 1.1vw;
    }
  }
  
  /* If the screen size is 600px wide or less, set the font-size of <div> to 30px */
  @media screen and (max-width: 600px) {
    .title {
      font-size: 3.3vw;
    }
    .description {
        font-size: 2vw;
    }
  }